import { CONTROL_TYPES, Control, ControlError, GSControl, Media, VALUE_TYPES } from '../models/FormControls';
import { Validators } from '@angular/forms';
import { FileType } from './FileType';
import { CustomValidators } from '../helpers/CustomValidators.helpers';
import { CHAMPION_CONTROLS, ChampionForm } from '../../internal-app/details/champion/models/ChampionFormControls';

export enum PORTFOLIO_CONTROLS {
  PRIMARY = 'primary',
  CHAMPIONS = 'champions',
  UUID = 'uuid',
  NAME = 'name',
  TYPE = 'type',
  DESCRIPTION = 'description',
  MEDIA = 'media',
  VIDEO = 'video',
  IMAGE = 'image',
  THUMBNAIL = 'thumbnail',
  GIVING_LINK = 'givingLink',
  CAUSE = 'cause',
  CATEGORIES = 'categories',
  COUNTRIES = 'countries',
  PROJECTS = 'projects',
  ORGANISATIONS = 'organisations',
  ITEM = 'item',
  POSITION = 'position',
  ALLOCATION = 'allocation',
  PORTFOLIO_RANK = 'portfolioRank',

  ENTITY_ITEM = 'entityItem',
  PORTFOLIO_PROFILE = 'portfolioProfile',
}

export const PORTFOLIO_FORM: GSControl = new GSControl({
  controlType: CONTROL_TYPES.FORM_GROUP,
  controls: {
    [PORTFOLIO_CONTROLS.PRIMARY]: new GSControl({
      controlType: CONTROL_TYPES.FORM_GROUP,
      label: 'ADMIN.SETUP.PORTFOLIO.STEPS.PRIMARY',
      controls: {
        [PORTFOLIO_CONTROLS.UUID]: new GSControl(),
        [PORTFOLIO_CONTROLS.PORTFOLIO_PROFILE]: new GSControl({
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.PORTFOLIO_PROFILE',
          valueType: VALUE_TYPES.NAME_UUID,
          validators: [Validators.required],
          errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.PORTFOLIO_PROFILE')],
        }),
        [PORTFOLIO_CONTROLS.NAME]: new GSControl({
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.GIVING_PORTFOLIO_NAME',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.GIVING_PORTFOLIO_NAME',
          max: 60,
          errors: [
            new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.NAME_REQUIRED'),
            new ControlError('nameExists', 'ADMIN.SETUP.PORTFOLIO.ERRORS.NAME_EXISTS'),
          ],
        }),
        [PORTFOLIO_CONTROLS.TYPE]: new GSControl({
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.GIVING_PORTFOLIO_TYPE',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.GIVING_PORTFOLIO_TYPE',
          errors: [
            new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.GIVING_PORTFOLIO_TYPE_REQUIRED'),
            new ControlError('invalidPercentage', 'ADMIN.SETUP.PORTFOLIO.ERRORS.GIVING_PORTFOLIO_INVALID_PERCENTAGE'),
          ],
        }),
        [PORTFOLIO_CONTROLS.DESCRIPTION]: new GSControl({
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.DESCRIPTION',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.DESCRIPTION',
          translatable: true,
          max: 1000,
          validators: [CustomValidators.maxValue(1000)],
          errors: [
            new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.DESCRIPTION_REQUIRED'),
            new ControlError('maxLengthError', 'ADMIN.SETUP.PORTFOLIO.ERRORS.DESCRIPTION_MAX_LENGTH'),
          ],
        }),
        [PORTFOLIO_CONTROLS.PORTFOLIO_RANK]: new GSControl({
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.PORTFOLIO_RANK',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.PORTFOLIO_RANK',
          translatable: true,
          value: 0,
          validators: [Validators.required, CustomValidators.maxValue(100), CustomValidators.minValue(0)],
          errors: [
            new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.PORTFOLIO_RANK_REQUIRED'),
            new ControlError('maxValue', 'ADMIN.SETUP.PORTFOLIO.ERRORS.PORTFOLIO_RANK_NOT_IN_RANGE'),
            new ControlError('minValue', 'ADMIN.SETUP.PORTFOLIO.ERRORS.PORTFOLIO_RANK_NOT_IN_RANGE'),
          ],
        }),
        [PORTFOLIO_CONTROLS.MEDIA]: new GSControl({
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.MEDIA',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.MEDIA',
          controlType: CONTROL_TYPES.FORM_GROUP,
          valueType: VALUE_TYPES.MEDIA,
          errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.MEDIA_REQUIRED')],
          controls: {
            [PORTFOLIO_CONTROLS.VIDEO]: new GSControl<Media>({
              label: 'ADMIN.SETUP.PORTFOLIO.LABELS.MEDIA',
              placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.MEDIA',
              value: new Media(FileType.PORTFOLIO_VIDEO, FileType.VIDEO),
            }),
            [PORTFOLIO_CONTROLS.IMAGE]: new GSControl<Media>({
              label: 'ADMIN.SETUP.PORTFOLIO.LABELS.MEDIA',
              placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.MEDIA',
              value: new Media(FileType.PORTFOLIO_IMAGE, FileType.IMG),
            }),
            [PORTFOLIO_CONTROLS.THUMBNAIL]: new GSControl<Media>({
              label: 'ADMIN.SETUP.PORTFOLIO.LABELS.VIDEO_THUMBNAIL',
              placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.VIDEO_THUMBNAIL',
              value: new Media(FileType.PORTFOLIO_THUMBNAIL_VIDEO, FileType.IMG),
            }),
          },
        }),
        [PORTFOLIO_CONTROLS.GIVING_LINK]: new GSControl({
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.GIVING_LINK',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.GIVING_LINK',
          max: 300,
          validators: [CustomValidators.website],
          errors: [
            new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.GIVING_LINK_REQUIRED'),
            new ControlError('invalidWebsite', 'ADMIN.SETUP.PORTFOLIO.ERRORS.GIVING_LINK_INVALID_WEBSITE'),
          ],
        }),
        [PORTFOLIO_CONTROLS.CAUSE]: new GSControl({
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.CAUSE',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.CAUSE',
          errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.CAUSE_REQUIRED')],
        }),
        [PORTFOLIO_CONTROLS.CATEGORIES]: new GSControl({
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.CATEGORIES',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.CATEGORIES',
          errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.CATEGORIES_REQUIRED')],
        }),
        [PORTFOLIO_CONTROLS.COUNTRIES]: new GSControl({
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.INFLUENCED_COUNTRIES',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.INFLUENCED_COUNTRIES',
          errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.INFLUENCED_COUNTRIES_REQUIRED')],
        }),
        [PORTFOLIO_CONTROLS.PROJECTS]: new GSControl({
          controlType: CONTROL_TYPES.FORM_ARRAY,
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.PROJECTS',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.PROJECTS',
          min: 1,
          max: 50,
          controls: {
            item: new GSControl({
              controlType: CONTROL_TYPES.FORM_GROUP,
              controls: {
                [PORTFOLIO_CONTROLS.UUID]: new GSControl(),
                [PORTFOLIO_CONTROLS.ITEM]: new GSControl({
                  label: 'ADMIN.SETUP.PORTFOLIO.LABELS.SELECT_PROJECT',
                  placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.SEARCH_PROJECT',
                  // validators: [CustomValidators.requiredObject],
                  value: '',
                  errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.SELECT_PROJECT_REQUIRED')],
                }),
                [PORTFOLIO_CONTROLS.ALLOCATION]: new GSControl({
                  label: 'ADMIN.SETUP.PORTFOLIO.LABELS.ALLOCATION',
                  placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.PROJECT_ALLOCATION',
                  min: 0,
                  max: 100,
                  errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.ALLOCATION_REQUIRED')],
                }),
                [PORTFOLIO_CONTROLS.POSITION]: new GSControl<number>({
                  value: 1,
                }),
              },
            }),
          },
        }),
        [PORTFOLIO_CONTROLS.ORGANISATIONS]: new GSControl({
          controlType: CONTROL_TYPES.FORM_ARRAY,
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.ORGANISATIONS',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.ORGANISATIONS',
          min: 1,
          max: 50,
          controls: {
            item: new GSControl({
              controlType: CONTROL_TYPES.FORM_GROUP,
              controls: {
                [PORTFOLIO_CONTROLS.UUID]: new GSControl(),
                [PORTFOLIO_CONTROLS.ITEM]: new GSControl({
                  label: 'ADMIN.SETUP.PORTFOLIO.LABELS.SELECT_ORGANISATION',
                  placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.SEARCH_ORGANISATION',
                  errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.SELECT_ORGANISATION_REQUIRED')],
                }),
                [PORTFOLIO_CONTROLS.ALLOCATION]: new GSControl({
                  label: 'ADMIN.SETUP.PORTFOLIO.LABELS.ALLOCATION',
                  placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.ORGANISATION_ALLOCATION',
                  min: 0,
                  max: 100,
                  errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.ALLOCATION_REQUIRED')],
                }),
                [PORTFOLIO_CONTROLS.POSITION]: new GSControl<number>({
                  value: 1,
                }),
              },
            }),
          },
        }),
      },
    }),
    [PORTFOLIO_CONTROLS.CHAMPIONS]: new GSControl<any>({
      controlType: CONTROL_TYPES.FORM_ARRAY,
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPIONS',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPIONS',
      controls: {
        item: new GSControl({
          controlType: CONTROL_TYPES.FORM_GROUP,
          controls: {
            [CHAMPION_CONTROLS.CHAMPION]: new GSControl({
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_2',
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.POSITION]: new GSControl(),
            ...ChampionForm.controls,
            [CHAMPION_CONTROLS.VIDEO_PRESENTATION]: new GSControl({
              ...ChampionForm.controls[CHAMPION_CONTROLS.VIDEO_PRESENTATION],
              value: new Media(FileType.CHAMPION_ENTITY_VIDEO, FileType.VIDEO),
            }),
            [CHAMPION_CONTROLS.DUE_DILIGENCE_REPORT_FILE]: new GSControl({
              ...ChampionForm.controls[CHAMPION_CONTROLS.DUE_DILIGENCE_REPORT_FILE],
              value: new Media(FileType.CHAMPION_ENTITY_DOCUMENT, FileType.DOC),
            }),
          },
        }),
      },
    }),
  },
});

export const PORTFOLIO_FORM_PROFILE: Control = new Control({
  controlType: CONTROL_TYPES.FORM_GROUP,
  controls: {
    [PORTFOLIO_CONTROLS.PRIMARY]: new Control({
      key: PORTFOLIO_CONTROLS.PRIMARY,
      controlType: CONTROL_TYPES.FORM_GROUP,
      label: 'ADMIN.SETUP.PORTFOLIO.STEPS.PRIMARY',
      controls: {
        [PORTFOLIO_CONTROLS.UUID]: new Control({
          key: PORTFOLIO_CONTROLS.UUID,
        }),
        [PORTFOLIO_CONTROLS.NAME]: new Control({
          key: PORTFOLIO_CONTROLS.NAME,
          externalKey: 'portfolioName',
          profileDisabled: true,
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.GIVING_PORTFOLIO_NAME',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.GIVING_PORTFOLIO_NAME',
          validators: [Validators.required],
          maxLength: 60,
          errors: [
            new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.NAME_REQUIRED'),
            new ControlError('nameExists', 'ADMIN.SETUP.PORTFOLIO.ERRORS.NAME_EXISTS'),
          ],
        }),
        [PORTFOLIO_CONTROLS.TYPE]: new Control({
          key: PORTFOLIO_CONTROLS.TYPE,
          externalKey: 'givingPortfolioType',
          profileDisabled: true,
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.GIVING_PORTFOLIO_TYPE',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.GIVING_PORTFOLIO_TYPE',
          validators: [Validators.required],
          errors: [
            new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.GIVING_PORTFOLIO_TYPE_REQUIRED'),
            new ControlError('invalidPercentage', 'ADMIN.SETUP.PORTFOLIO.ERRORS.GIVING_PORTFOLIO_INVALID_PERCENTAGE'),
          ],
        }),
        [PORTFOLIO_CONTROLS.DESCRIPTION]: new Control({
          key: PORTFOLIO_CONTROLS.DESCRIPTION,
          externalKey: 'description',
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.DESCRIPTION',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.DESCRIPTION',
          translatable: true,
          maxLength: 1000,
          validators: [Validators.required],
          errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.DESCRIPTION_REQUIRED')],
        }),
        [PORTFOLIO_CONTROLS.PORTFOLIO_RANK]: new Control({
          key: PORTFOLIO_CONTROLS.PORTFOLIO_RANK,
          externalKey: 'portfolioScore',
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.PORTFOLIO_RANK',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.PORTFOLIO_RANK',
          translatable: true,
          profileDisabled: true,
          value: 0,
          validators: [CustomValidators.maxValue(100), CustomValidators.minValue(0)],
          errors: [
            new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.PORTFOLIO_RANK_REQUIRED'),
            new ControlError('maxValue', 'ADMIN.SETUP.PORTFOLIO.ERRORS.PORTFOLIO_RANK_NOT_IN_RANGE'),
            new ControlError('minValue', 'ADMIN.SETUP.PORTFOLIO.ERRORS.PORTFOLIO_RANK_NOT_IN_RANGE'),
          ],
        }),
        [PORTFOLIO_CONTROLS.MEDIA]: new Control({
          key: PORTFOLIO_CONTROLS.MEDIA,
          externalKey: 'portfolioMedia',
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.MEDIA',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.MEDIA',
          controlType: CONTROL_TYPES.FORM_CONTROL,
          validators: [CustomValidators.imageOrVideoRequired(PORTFOLIO_CONTROLS.IMAGE, PORTFOLIO_CONTROLS.VIDEO)],
          errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.MEDIA_REQUIRED')],
          controls: {
            [PORTFOLIO_CONTROLS.IMAGE]: new Control<Media>({
              label: 'ADMIN.SETUP.PORTFOLIO.LABELS.MEDIA',
              // key: PORTFOLIO_CONTROLS.MEDIA,
              // externalKey: 'portfolioMedia',
              placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.MEDIA',
              value: new Media(FileType.PORTFOLIO_IMAGE, FileType.IMG),
            }),
            [PORTFOLIO_CONTROLS.THUMBNAIL]: new Control<Media>({
              label: 'ADMIN.SETUP.PORTFOLIO.LABELS.VIDEO_THUMBNAIL',
              placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.VIDEO_THUMBNAIL',
              value: new Media(FileType.PORTFOLIO_THUMBNAIL_VIDEO, FileType.IMG),
            }),
            [PORTFOLIO_CONTROLS.VIDEO]: new Control<Media>({
              label: 'ADMIN.SETUP.PORTFOLIO.LABELS.MEDIA',
              placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.MEDIA',
              value: new Media(FileType.PORTFOLIO_VIDEO, FileType.VIDEO),
            }),
          },
        }),
        [PORTFOLIO_CONTROLS.GIVING_LINK]: new Control({
          key: PORTFOLIO_CONTROLS.GIVING_LINK,
          externalKey: 'givingLink',
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.GIVING_LINK',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.GIVING_LINK',
          maxLength: 300,
          validators: [Validators.required, CustomValidators.website],
          errors: [
            new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.GIVING_LINK_REQUIRED'),
            new ControlError('invalidWebsite', 'ADMIN.SETUP.PORTFOLIO.ERRORS.GIVING_LINK_INVALID_WEBSITE'),
          ],
        }),
        [PORTFOLIO_CONTROLS.CAUSE]: new Control({
          key: PORTFOLIO_CONTROLS.CAUSE,
          externalKey: 'cause',
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.CAUSE',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.CAUSE',
          validators: [Validators.required],
          errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.CAUSE_REQUIRED')],
        }),
        [PORTFOLIO_CONTROLS.CATEGORIES]: new Control({
          key: PORTFOLIO_CONTROLS.CATEGORIES,
          externalKey: 'categories',
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.CATEGORIES',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.CATEGORIES',
          validators: [Validators.required],
          errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.CATEGORIES_REQUIRED')],
        }),
        [PORTFOLIO_CONTROLS.COUNTRIES]: new Control({
          key: PORTFOLIO_CONTROLS.COUNTRIES,
          externalKey: 'influencedCountries',
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.INFLUENCED_COUNTRIES',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.INFLUENCED_COUNTRIES',
          validators: [Validators.required],
          errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.INFLUENCED_COUNTRIES_REQUIRED')],
        }),
        [PORTFOLIO_CONTROLS.PROJECTS]: new Control({
          key: PORTFOLIO_CONTROLS.PROJECTS,
          externalKey: 'projects',
          profileDisabled: true,
          controlType: CONTROL_TYPES.FORM_ARRAY,
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.PROJECTS',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.PROJECTS',
          minLength: 1,
          maxLength: 50,
          controls: {
            item: new Control({
              controlType: CONTROL_TYPES.FORM_GROUP,
              controls: {
                [PORTFOLIO_CONTROLS.UUID]: new Control({
                  key: PORTFOLIO_CONTROLS.UUID,
                  externalKey: 'uuid',
                }),
                [PORTFOLIO_CONTROLS.ENTITY_ITEM]: new Control({
                  key: PORTFOLIO_CONTROLS.ENTITY_ITEM,
                  externalKey: 'itemName',
                  profileDisabled: true,
                  label: 'ADMIN.SETUP.PORTFOLIO.LABELS.SELECT_PROJECT',
                  placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.SEARCH_PROJECT',
                  validators: [CustomValidators.requiredObject],
                  value: '',
                  errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.SELECT_PROJECT_REQUIRED')],
                }),
                [PORTFOLIO_CONTROLS.ALLOCATION]: new Control({
                  key: PORTFOLIO_CONTROLS.ALLOCATION,
                  externalKey: 'allocation',
                  profileDisabled: true,
                  label: 'ADMIN.SETUP.PORTFOLIO.LABELS.ALLOCATION',
                  placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.PROJECT_ALLOCATION',
                  validators: [Validators.required],
                  minLength: 0,
                  maxLength: 100,
                  errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.ALLOCATION_REQUIRED')],
                }),
                [PORTFOLIO_CONTROLS.POSITION]: new Control<number>({
                  key: PORTFOLIO_CONTROLS.POSITION,
                  externalKey: 'position',
                  value: 1,
                }),
              },
            }),
          },
        }),
        [PORTFOLIO_CONTROLS.ORGANISATIONS]: new Control({
          key: PORTFOLIO_CONTROLS.ORGANISATIONS,
          externalKey: 'organizations',
          profileDisabled: true,
          controlType: CONTROL_TYPES.FORM_ARRAY,
          label: 'ADMIN.SETUP.PORTFOLIO.LABELS.ORGANISATIONS',
          placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.ORGANISATIONS',
          minLength: 1,
          maxLength: 50,
          controls: {
            item: new Control({
              controlType: CONTROL_TYPES.FORM_GROUP,
              controls: {
                [PORTFOLIO_CONTROLS.UUID]: new Control({
                  key: PORTFOLIO_CONTROLS.UUID,
                  externalKey: 'uuid',
                }),
                [PORTFOLIO_CONTROLS.ENTITY_ITEM]: new Control({
                  key: PORTFOLIO_CONTROLS.ENTITY_ITEM,
                  externalKey: 'itemName',
                  profileDisabled: true,
                  label: 'ADMIN.SETUP.PORTFOLIO.LABELS.SELECT_ORGANISATION',
                  placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.SEARCH_ORGANISATION',
                  validators: [CustomValidators.requiredObject],
                  errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.SELECT_ORGANISATION_REQUIRED')],
                }),
                [PORTFOLIO_CONTROLS.ALLOCATION]: new Control({
                  key: PORTFOLIO_CONTROLS.ALLOCATION,
                  externalKey: 'allocation',
                  profileDisabled: true,
                  label: 'ADMIN.SETUP.PORTFOLIO.LABELS.ALLOCATION',
                  placeholder: 'ADMIN.SETUP.PORTFOLIO.PLACEHOLDERS.ORGANISATION_ALLOCATION',
                  validators: [Validators.required],
                  minLength: 0,
                  maxLength: 100,
                  errors: [new ControlError('required', 'ADMIN.SETUP.PORTFOLIO.ERRORS.ALLOCATION_REQUIRED')],
                }),
                [PORTFOLIO_CONTROLS.POSITION]: new Control<number>({
                  key: PORTFOLIO_CONTROLS.POSITION,
                  externalKey: 'position',
                  value: 1,
                }),
              },
            }),
          },
        }),
      },
    }),
    [PORTFOLIO_CONTROLS.CHAMPIONS]: new Control<any>({
      key: PORTFOLIO_CONTROLS.CHAMPIONS,
      externalKey: 'portfolioChampions',
      controlType: CONTROL_TYPES.FORM_ARRAY,
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPIONS',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPIONS',
      controls: {
        item: new Control({
          controlType: CONTROL_TYPES.FORM_GROUP,
          controls: {
            [CHAMPION_CONTROLS.CHAMPION]: new Control({
              key: CHAMPION_CONTROLS.CHAMPION,
              externalKey: 'portfolioChampion',
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_2',
              validators: [Validators.required],
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.POSITION]: new Control(),
            [CHAMPION_CONTROLS.DUE_DILIGENCE_LEVEL]: new Control({
              key: CHAMPION_CONTROLS.DUE_DILIGENCE_LEVEL,
              externalKey: 'portfolioChampionDueDiligenceLevel',
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_DUE_DILIGENCE_LEVEL',
              // validators: [Validators.required],
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_DUE_DILIGENCE_LEVEL_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.GRANTS_AWARDED]: new Control({
              key: CHAMPION_CONTROLS.GRANTS_AWARDED,
              externalKey: 'portfolioChampionGrantsAwarded',
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_GRANTS_AWARDED',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_GRANTS_AWARDED',
              validators: [Validators.required],
              value: 0,
              optionalText: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_GRANTS_AWARDED_DESCRIPTION',
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_GRANTS_AWARDED_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.GRANTS_AWARDED_CURRENCY]: new Control({
              key: CHAMPION_CONTROLS.GRANTS_AWARDED_CURRENCY,
              externalKey: 'portfolioChampionCurrency',
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_GRANTS_AWARDED_CURRENCY',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_GRANTS_AWARDED_CURRENCY',
              validators: [Validators.required],
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_GRANTS_AWARDED_CURRENCY_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.QUOTE]: new Control({
              key: CHAMPION_CONTROLS.QUOTE,
              externalKey: 'portfolioChampionQuote',
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_QUOTE',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_QUOTE_2',
              maxLength: 1000,
              validators: [Validators.required],
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_QUOTE_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.VIDEO_PRESENTATION]: new Control({
              key: CHAMPION_CONTROLS.VIDEO_PRESENTATION,
              externalKey: 'portfolioChampionVideoPresentation',
              value: new Media(FileType.CHAMPION_ENTITY_VIDEO, FileType.VIDEO),
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_VIDEO_PRESENTATION',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_VIDEO_PRESENTATION',
              // validators: [CustomValidators.mediaRequired],
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_VIDEO_PRESENTATION_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.HISTORY_DUE_DILIGENCE]: new Control({
              key: CHAMPION_CONTROLS.HISTORY_DUE_DILIGENCE,
              externalKey: 'portfolioChampionHistory',
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_HISTORY_DUE_DILIGENCE',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_HISTORY_DUE_DILIGENCE',
              maxLength: 1000,
              // validators: [Validators.required],
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_HISTORY_DUE_DILIGENCE_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.DUE_DILIGENCE_REPORT]: new Control({
              key: CHAMPION_CONTROLS.DUE_DILIGENCE_REPORT,
              externalKey: 'portfolioChampionDueDiligenceReport',
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_DUE_DILIGENCE_REPORT',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_DUE_DILIGENCE_REPORT',
              // validators: [Validators.required],
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_DUE_DILIGENCE_REPORT_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.DUE_DILIGENCE_REPORT_FILE]: new Control({
              key: CHAMPION_CONTROLS.DUE_DILIGENCE_REPORT_FILE,
              externalKey: 'portfolioChampionDueDiligenceReportFile',
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_DUE_DILIGENCE_REPORT_FILE',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_DUE_DILIGENCE_REPORT_FILE',
              value: new Media(FileType.CHAMPION_ENTITY_DOCUMENT, FileType.DOC),
              validators: [CustomValidators.mediaRequired],
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_DUE_DILIGENCE_REPORT_FILE_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.IMPACT_STORY]: new Control({
              key: CHAMPION_CONTROLS.IMPACT_STORY,
              externalKey: 'portfolioChampionImpactStory',
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_IMPACT_STORY',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_IMPACT_STORY_1',
              maxLength: 1000,
              // validators: [Validators.required],
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_IMPACT_STORY_REQUIRED')],
            }),
            [CHAMPION_CONTROLS.TOP_RISKS]: new Control({
              key: CHAMPION_CONTROLS.TOP_RISKS,
              externalKey: 'portfolioChampionTopRisks',
              label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_TOP_RISKS',
              placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_TOP_RISKS_1',
              maxLength: 1000,
              // validators: [Validators.required],
              errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_TOP_RISKS_REQUIRED')],
            }),
          },
        }),
      },
    }),
  },
});
