import Utils from '../helpers/Utils';
import { Media } from './FormControls';
import { UUIDName } from './GenericObject';

export interface GivingDetails {
  donationAmount: number;
  transactionProcessingOption: string;
  entityType: string;
  entityUuid: string;
  optionalMessageToOrganisation?: string;
  commitToFulfilPledge: boolean;
}

export interface EntityChampionDto {
  firstName: string;
  lastName: string;
  entityName?: string;
  coverImage: any;
  currency: UUIDName;
  document: any;
  email?: string;
  dueDiligenceLevel: string;
  grantsAwardedNumber: number;
  history: string;
  impactStory: string;
  quote: string;
  topRisks: string;
  uuid: string;
  video: any;
  photo: any;

  championTestimonialUuid: string;
  yearsOfEngagement: string;
  testimonial: string;
  photoOrVideo: Media;
}

export class EntityChampion {
  name: string;
  entityName: string;
  coverImageUrl: string;
  document: ChampionDocument;
  videoUrl: string;
  photoUrl: string;
  financialInvestment: string;
  dueDiligenceLevel: string;
  email: string;
  history: string;
  impactStory: string;
  quote: string;
  topRisks: string;
  uuid: string;
  currencySymbol: string;
  selected: boolean;
  championTestimonialUuid: string;
  yearsOfEngagement: string;
  testimonial: string;
  photoOrVideo: Media;

  constructor(data: EntityChampionDto) {
    this.name = `${data.firstName} ${data.lastName}`;
    this.entityName = data.entityName && data.entityName !== '!no' ? data.entityName : this.name;
    this.coverImageUrl = data.coverImage?.url;
    this.document = data.document && new ChampionDocument(data.document);
    this.videoUrl = data.video?.url;
    this.financialInvestment = !data.grantsAwardedNumber ? null : data.grantsAwardedNumber.toString();
    this.dueDiligenceLevel = data.dueDiligenceLevel;
    this.history = data.history;
    this.email = data.email;
    this.quote = data.quote;
    this.impactStory = data.impactStory;
    this.topRisks = data.topRisks;
    this.uuid = data.uuid;
    this.currencySymbol = Utils.setCurrencySymbol(data.currency?.name);
    this.championTestimonialUuid = data.championTestimonialUuid;
    this.yearsOfEngagement = data.yearsOfEngagement;
    this.testimonial = data.testimonial;
    this.photoOrVideo = data.photoOrVideo;
    this.photoUrl = data.photo?.url;
  }

  select(): void {
    this.selected = true;
  }

  deselect(): void {
    this.selected = false;
  }
}

export class ChampionDocument {
  url: string;
  name: string;
  type: string;

  constructor(data) {
    this.url = data.url;
    this.name = Utils.getFileName(data.filename);
    this.type = data.type;
  }
}
