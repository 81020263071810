import { CONTROL_TYPES, ControlError, GSControl, Media, VALUE_TYPES } from '../../../../_core/models/FormControls';
import { FileType } from '../../../../_core/constants/FileType';
import { Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/_core/helpers/CustomValidators.helpers';

export enum CHAMPION_CONTROLS {
  CHAMPION = 'champion',
  DUE_DILIGENCE_LEVEL = 'dueDiligenceLevel',
  GRANTS_AWARDED = 'grantsAwarded',
  GRANTS_AWARDED_CURRENCY = 'grantsAwardedCurrency',
  QUOTE = 'quote',
  VIDEO_PRESENTATION = 'videoPresentation',
  HISTORY_DUE_DILIGENCE = 'historyDueDiligence',
  DUE_DILIGENCE_REPORT = 'dueDiligenceReport',
  DUE_DILIGENCE_REPORT_FILE = 'dueDiligenceFile',
  IMPACT_STORY = 'impactStory',
  TOP_RISKS = 'topRisks',
  POSITION = 'position',
}

export const ChampionForm: GSControl = new GSControl({
  controlType: CONTROL_TYPES.FORM_GROUP,
  controls: {
    [CHAMPION_CONTROLS.DUE_DILIGENCE_LEVEL]: new GSControl({
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_DUE_DILIGENCE_LEVEL',
      // validators: [Validators.required],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_DUE_DILIGENCE_LEVEL_REQUIRED')],
    }),
    [CHAMPION_CONTROLS.GRANTS_AWARDED]: new GSControl({
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_GRANTS_AWARDED',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_GRANTS_AWARDED',
      // validators: [Validators.required],
      value: 0,
      optionalText: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_GRANTS_AWARDED_DESCRIPTION',
      errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_GRANTS_AWARDED_REQUIRED')],
    }),
    [CHAMPION_CONTROLS.GRANTS_AWARDED_CURRENCY]: new GSControl({
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_GRANTS_AWARDED_CURRENCY',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_GRANTS_AWARDED_CURRENCY',
      // validators: [Validators.required],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_GRANTS_AWARDED_CURRENCY_REQUIRED')],
    }),
    [CHAMPION_CONTROLS.QUOTE]: new GSControl({
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_QUOTE',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_QUOTE_2',
      max: 1000,
      validators: [Validators.required],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_QUOTE_REQUIRED')],
    }),
    [CHAMPION_CONTROLS.VIDEO_PRESENTATION]: new GSControl({
      value: new Media(FileType.CHAMPION_ENTITY_VIDEO, FileType.VIDEO),
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_VIDEO_PRESENTATION',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_VIDEO_PRESENTATION',
      // validators: [CustomValidators.mediaRequired],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_VIDEO_PRESENTATION_REQUIRED')],
    }),
    [CHAMPION_CONTROLS.HISTORY_DUE_DILIGENCE]: new GSControl({
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_HISTORY_DUE_DILIGENCE',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_HISTORY_DUE_DILIGENCE',
      max: 1000,
      // validators: [Validators.required],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_HISTORY_DUE_DILIGENCE_REQUIRED')],
    }),
    [CHAMPION_CONTROLS.DUE_DILIGENCE_REPORT]: new GSControl({
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_DUE_DILIGENCE_REPORT',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_DUE_DILIGENCE_REPORT',
      // validators: [Validators.required],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_DUE_DILIGENCE_REPORT_REQUIRED')],
    }),
    [CHAMPION_CONTROLS.DUE_DILIGENCE_REPORT_FILE]: new GSControl({
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_DUE_DILIGENCE_REPORT_FILE',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_DUE_DILIGENCE_REPORT_FILE',
      value: new Media(FileType.CHAMPION_ENTITY_DOCUMENT, FileType.DOC),
      // validators: [CustomValidators.mediaRequired],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_DUE_DILIGENCE_REPORT_FILE_REQUIRED')],
    }),
    [CHAMPION_CONTROLS.IMPACT_STORY]: new GSControl({
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_IMPACT_STORY',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_IMPACT_STORY_1',
      max: 1000,
      // validators: [Validators.required],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_IMPACT_STORY_REQUIRED')],
    }),
    [CHAMPION_CONTROLS.TOP_RISKS]: new GSControl({
      label: 'CATALOG.CHAMPION.CHAMPION_FORM.LABELS.CHAMPION_TOP_RISKS',
      placeholder: 'CATALOG.CHAMPION.CHAMPION_FORM.PLACEHOLDERS.CHAMPION_TOP_RISKS_1',
      max: 1000,
      // validators: [Validators.required],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.CHAMPION_FORM.ERRORS.CHAMPION_TOP_RISKS_REQUIRED')],
    }),
  },
});

export enum CHAMPION_CONTROLS_2 {
  ORGANIZATION_ENGAGEMENT = 'organizationEngagement',
  START_MONTH = 'engagementStartMonth',
  START_YEAR = 'engagementStartYear',
  GRANTS_AWARDED_CURRENCY = 'grantsAwardedCurrency',
  GRANTS_AWARDED = 'grantsAwarded',
  TESTIMONIAL = 'testimonial',
  CHAMPION_MEDIA = 'championMedia',
  TESTIMONIAL_VISIBILITY = 'testimonialVisibility',
  VIDEO = 'video',
  IMAGE = 'image',
}

export const ChampionForm2: GSControl = new GSControl({
  controlType: CONTROL_TYPES.FORM_GROUP,
  controls: {
    [CHAMPION_CONTROLS_2.ORGANIZATION_ENGAGEMENT]: new GSControl({
      label: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.LABELS.ENGAGEMENT',
      placeholder: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.PLACEHOLDERS.ENGAGEMENT',
      controlType: CONTROL_TYPES.FORM_GROUP,
      controls: {
        [CHAMPION_CONTROLS_2.START_YEAR]: new GSControl({
          label: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.LABELS.ENGAGEMENT_SINCE',
          placeholder: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.PLACEHOLDERS.ENGAGEMENT_SINCE',
          validators: [Validators.required],
          errors: [new ControlError('required', 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.ERRORS.ENGAGEMENT_YEAR_REQUIRED')],
        }),
        [CHAMPION_CONTROLS_2.START_MONTH]: new GSControl({
          validators: [Validators.required],
          errors: [new ControlError('required', 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.ERRORS.ENGAGEMENT_MONTH_REQUIRED')],
        }),
      },
    }),
    [CHAMPION_CONTROLS_2.GRANTS_AWARDED]: new GSControl({
      label: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.LABELS.GRANTS_AWARDED',
      placeholder: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.PLACEHOLDERS.GRANTS_AWARDED',
      validators: [Validators.required],
      value: 0,
      optionalText: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.PLACEHOLDERS.GRANTS_AWARDED_DESCRIPTION',
      errors: [new ControlError('required', 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.ERRORS.GRANTS_AWARDED_REQUIRED')],
    }),
    [CHAMPION_CONTROLS_2.GRANTS_AWARDED_CURRENCY]: new GSControl({
      label: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.LABELS.GRANTS_AWARDED_CURRENCY',
      placeholder: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.PLACEHOLDERS.GRANTS_AWARDED_CURRENCY',
      validators: [Validators.required],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.ERRORS.GRANTS_AWARDED_CURRENCY_REQUIRED')],
    }),
    [CHAMPION_CONTROLS_2.TESTIMONIAL]: new GSControl({
      label: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.LABELS.TESTIMONIAL',
      placeholder: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.PLACEHOLDERS.TESTIMONIAL',
      validators: [Validators.required, CustomValidators.maxLength(1000)],
      max: 1000,
      errors: [
        new ControlError('required', 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.ERRORS.TESTIMONIAL_REQUIRED'),
        new ControlError('maxlength', 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.ERRORS.TESTIMONIAL_MAX_LENGTH'),
      ],
    }),
    [CHAMPION_CONTROLS_2.CHAMPION_MEDIA]: new GSControl({
      label: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.LABELS.MEDIA',
      placeholder: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.PLACEHOLDERS.MEDIA',
      valueType: VALUE_TYPES.MEDIA,
      controlType: CONTROL_TYPES.FORM_GROUP,
      controls: {
        [CHAMPION_CONTROLS_2.VIDEO]: new GSControl<Media>({
          value: new Media(FileType.CHAMPION_ENTITY_VIDEO, FileType.VIDEO),
        }),
        [CHAMPION_CONTROLS_2.IMAGE]: new GSControl<Media>({
          value: new Media(FileType.CHAMPION_HIGH_QUALITY_IMAGE, FileType.IMG),
        }),
      },
    }),
    [CHAMPION_CONTROLS_2.TESTIMONIAL_VISIBILITY]: new GSControl({
      label: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.LABELS.TESTIMONIAL_VISIBILITY',
      placeholder: 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.LABELS.TESTIMONIAL_VISIBILITY',
      validators: [Validators.required],
      errors: [new ControlError('required', 'CATALOG.CHAMPION.NEW_CHAMPION_FORM.ERRORS.TESTIMONIAL_VISIBILITY_REQUIRED')],
    }),
  },
});
